import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import PageDetails from '../../components/PageDetails';
import CompanyQuote from '../../components/CompanyQuote';
import WhatWeDo from './WhatWeDo';
import ServiceWeProvide from './ServiceWeProvide';

const propTypes = {
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  detailsTitle: PropTypes.string.isRequired,
  detailsDescription: PropTypes.shape({}).isRequired,
  serviceWeProvide: PropTypes.array.isRequired,
  businessFields: PropTypes.array.isRequired,
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    icons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

const ServicesPagv2 = ({
  title,
  subheader,
  detailsTitle,
  detailsDescription,
  serviceWeProvide,
  businessFields,
  companyQuote,
}) => (
  <Layout currentPage="/services-v2" data-test="servicePageContainer">
    <PageHeader title={title} description={subheader} />
    <ServiceWeProvide services={serviceWeProvide} />
    <PageDetails title={detailsTitle} description={detailsDescription} />
    <WhatWeDo businessFields={businessFields} />
    <CompanyQuote companyQuote={companyQuote} />
  </Layout>
);

ServicesPagv2.propTypes = propTypes;
export default ServicesPagv2;
