import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ServicesV2 from '../containers/Services-v2/index';

const propTypes = {
  data: PropTypes.shape({
    contentfulServicesPage: PropTypes.shape({
      title: PropTypes.string,
      subheader: PropTypes.shape({
        subheader: PropTypes.string,
      }),
      detailsTitle: PropTypes.string,
      detailsDescription: PropTypes.shape({}),
      serviceWeProvide: PropTypes.array,
      whatWeDo: PropTypes.array,
    }).isRequired,
    contentfulCompanyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      footerDescription: PropTypes.shape({}).isRequired,
      icons: PropTypes.arrayOf({
        fluid: PropTypes.shape({}),
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

const ServicesPage = ({ data }) => (
  <ServicesV2
    title={data.contentfulServicesPage.title}
    subheader={data.contentfulServicesPage.subheader.subheader}
    detailsTitle={data.contentfulServicesPage.detailsTitle}
    detailsDescription={data.contentfulServicesPage.detailsDescription}
    serviceWeProvide={data.contentfulServicesPage.serviceWeProvide}
    businessFields={data.contentfulServicesPage.whatWeDo}
    companyQuote={data.contentfulCompanyQuote}
  />
);

ServicesPage.propTypes = propTypes;
export default ServicesPage;

export const query = graphql`
  query {
    contentfulServicesPage: contentfulServicesPageV2 {
      title
      subheader {
        subheader
      }
      detailsTitle
      detailsDescription {
        json
      }
      whatWeDo {
        id
        title
        description {
          json
        }
        icon {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      serviceWeProvide {
        title
        slug
        subHeader {
          subHeader
        }
        detailsTitle
        detailsDescription {
          detailsDescription
        }
        icon {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
