import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Button from '../../../components/Button';
import s from './ServiceWeProvide.module.scss';

const propTypes = {
  services: PropTypes.array.isRequired,
};
const ServiceWeProvide = ({ services }) => (
  <div className={s.container} data-aos="fade-up" data-test="servicePageServicesWeProviceContainer">
    <div className={s.cardWrapper} data-aos="fade-up" data-aos-delay="300">
      <div className={s.cardOuter}>
        {services?.map((service, serviceIndex) => (
          <div
            className={serviceIndex === services.length - 1 ? s.flax50 : `${s.flax50}`}
            data-test="servicePageServicesWeProvice"
            key={service.title}
          >
            <div className={s.cardDetail}>
              <div className={s.emojiContainer}>
                <Img fluid={service.icon.fluid} className={s.logo} />
              </div>
              <h3 className={s.titleServices}>{service.title}</h3>
              <p className={s.subHeader}>{service.subHeader.subHeader}</p>
              <Link theme="grey" className={s.contact} to={`/services-v2/${service.slug}`}>
                <Button>See Details</Button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

ServiceWeProvide.propTypes = propTypes;
export default ServiceWeProvide;
