import React from 'react';
import PropTypes from 'prop-types';
import OurService from '../../../components/OurService';
import s from './WhatWeDo.module.scss';

const propTypes = {
  businessFields: PropTypes.array.isRequired,
};

const WhatWeDo = ({ businessFields }) => {
  return (
    <section className={s.container} data-test="servicePageWhatWeDoContainer">
      <div className={s.serviceRoot}>
        {businessFields?.map((service, i) => {
          let position;
          if (i % 3 === 0) position = 'left';
          if (i % 3 === 1) position = 'center';
          if (i % 3 === 2) position = 'right';
          return (
            <OurService
              icon={service.icon}
              title={service.title}
              description={service.description}
              position={position}
              key={service.id}
              data-test="servicePageOverServices"
            />
          );
        })}
      </div>
    </section>
  );
};

WhatWeDo.propTypes = propTypes;
export default WhatWeDo;
