import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import renderContent from '../../utils/rich-text-helpers';
import s from './OurService.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.array,
    }),
  }).isRequired,
  icon: PropTypes.shape({
    fluid: PropTypes.shape({}),
  }).isRequired,
  position: PropTypes.string,
};

const defaultProps = {
  position: 'left',
};

const OurService = ({ title, description, icon, position }) => {
  let align;
  if (position === 'left') align = s.left;
  if (position === 'center') align = s.center;
  if (position === 'right') align = s.right;

  return (
    <div className={`${s.root} ${align}`}>
      <div className={s.service} data-aos="fade-up" data-aos-delay="300">
        <div className={s.emojiContainer}>
          <Img fluid={icon.fluid} className={s.logo} />
        </div>
        <div className={s.contentContainer}>
          <h2 className={s.title}>{title}</h2>
          {description?.json?.content.map((content) => renderContent(content, s.paragraph))}
        </div>
      </div>
    </div>
  );
};
OurService.defaultProps = defaultProps;
OurService.propTypes = propTypes;
export default OurService;
